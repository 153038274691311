<template>
  <div class="d-flex ma-5" style="flex-wrap: wrap">
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
    <v-card style="width: 250px" class="ma-3">
      <v-card-text class="d-flex flex-column">
        <v-img
          :src="require('@/assets/samples/product.png')"
          width="100%"
          height="180"
          contain
        />
        <span style="font-size: 20px; font-weight: 500; margin-top: 15px">
          $980
        </span>
        <span
          style="color: #5b2cae; font-size: 18px; font-weight: 500;margin-top: 15px"
        >
          Chrono Caviar
        </span>
        <span style="color: #5b2cae; font-size: 12px; margin-top: 15px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          interdum placerat pellentesque ultrices.
        </span>
        <span
          style="color: #5b2cae; font-size: 14px; margin-top: 15px; text-decoration: underline"
        >
          View more details
        </span>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {};
</script>
